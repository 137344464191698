<template>
  <div class="csn-game">
    <GameLists
      :getList="getList"
      :itemsPerScreen="itemsPerScreen"
      :hasVericalUpperRow="true"
      :slug="slug"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  Module,
  CURRENT_LOCATION,
  GAME_VENDOR,
  VENDOR_DICTIONARY,
} from '@/constants'
import GameLists from '@/components/GameLists'
import { cardsPerScreenCalculationMixin } from '@/mixins'
import { GameApi } from '@/api'

export default {
  name: GAME_VENDOR,
  mixins: [cardsPerScreenCalculationMixin],
  components: {
    GameLists,
  },
  computed: {
    ...mapState(Module.LOCATION, { CURRENT_LOCATION }),
    ...mapState(Module.VENDOR, { VENDOR_DICTIONARY }),
    slug() {
      return this.CURRENT_LOCATION?.params?.slug
    },
    id() {
      return this.VENDOR_DICTIONARY[this.slug].id
    },
  },
  methods: {
    getList(args) {
      return GameApi.getGameVendor({ id: this.id, ...args })
    },
  },
}
</script>
