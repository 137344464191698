<template>
  <div class="row csn-game-lists">
    <div class="col-md-12">
      <Breadcrumbs />
    </div>

    <div v-if="isEmptyList" class="csn-game-lists-empty">
      <span>{{ t('empty_list') }}</span>
    </div>

    <div class="csn-horizontal-list">
      <div :class="gameListClass">
        <button @click="handleLeftButtonClick" :class="leftButtonClass">
          <SlideArrow :isBack="true" />
        </button>
        <button @click="handleRightButtonClick" :class="rightButtonClass">
          <SlideArrow />
        </button>

        <slider :options="options" @slide="slide" :ref="ref">
          <slideritem
            v-for="(game, index) in derivedHorizontalList"
            :key="index"
          >
            <GameBoxWithHover :game="game" :isHigh="true" />
          </slideritem>
          <div slot="loading"></div>
        </slider>
      </div>
    </div>

    <div class="card-deck text-center csn-vertical-game-list-container">
      <div class="row-fluid casino-game-list csn-vertical-game-list">
        <div
          v-for="(game, index) in derivedVerticalList"
          :key="index"
          class="csn-vertical-game-wrapper"
        >
          <GameBoxWithHover :game="game" />
        </div>
      </div>
    </div>
    <VerticalScrollPaginator
      :hasMore="hasMore"
      :isPending="isLoading"
      :loadMore="updateList"
    />
  </div>
</template>
<script>
import { slider, slideritem } from 'vue-concise-slider'
import {
  GAME_LISTS,
  EMPTY_STRING,
  Digit,
  ResponseState,
  EMPTY_ARRAY,
  ID,
  CARUSEL_WRAPPER,
  CSN_HORIZONTAL_GAME_LIST,
  CSN_HORIZONTAL_GAME_LIST_HIGH,
  SLIDER,
  SLIDE_PRE,
  SLIDE_NEXT,
  CSN_HORIZONTAL_GAME_LIST_BUTTON_LEFT,
  CSN_HORIZONTAL_GAME_LIST_BUTTON_RIGHT,
  CSN_HORIZONTAL_GAME_LIST_BUTTON_HIDDEN,
} from '@/constants'
import {
  generateUUID,
  callFn,
  requestTimeout,
  cancelTimeout,
  indexBy,
  pipe,
  remove,
  isEmpty,
} from '@/helpers'
import * as Game from '@/models/getters/game'

export default {
  name: GAME_LISTS,
  components: {
    Breadcrumbs: () => import('@/components/Breadcrumbs'),
    GameBoxWithHover: () => import('@/components/GameBoxWithHover'),
    VerticalScrollPaginator: () =>
      import('@/components/VerticalScrollPaginator'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    slider,
    slideritem,
  },
  data: () => ({
    list: null,
    page: Digit.NOUGHT,
    isLoading: false,
    showsLeftButton: false,
    showsRightButton: false,
    resetRequest: null,
    total: null,
  }),
  props: {
    itemsPerScreen: Number,
    hasVericalUpperRow: Boolean,
    getList: Function,
    slug: [String, Object, null],
  },
  computed: {
    Game: () => Game,
    t() {
      return this.$createComponentTranslator(GAME_LISTS)
    },
    hasMore() {
      return this.page * Digit.TEN < this.total
    },
    derivedHorizontalList() {
      return this.hasVericalUpperRow
        ? (this.list || []).slice(Digit.NOUGHT, Digit.SEVEN)
        : EMPTY_ARRAY
    },
    derivedVerticalList() {
      return this.hasVericalUpperRow
        ? (this.list || []).slice(Digit.SEVEN)
        : this.list || []
    },
    options() {
      return {
        currentPage: Digit.NOUGHT,
        pagination: false,
        slidesToScroll: this.itemsPerScreen,
        loopedSlides: this.itemsPerScreen,
      }
    },
    ref: () => `${SLIDER}${generateUUID()}`,
    gameListClass() {
      return [
        CARUSEL_WRAPPER,
        CSN_HORIZONTAL_GAME_LIST,
        CSN_HORIZONTAL_GAME_LIST_HIGH,
      ]
    },
    leftButtonClass() {
      return [
        CSN_HORIZONTAL_GAME_LIST_BUTTON_LEFT,
        this.showsLeftButton
          ? EMPTY_STRING
          : CSN_HORIZONTAL_GAME_LIST_BUTTON_HIDDEN,
      ]
    },
    rightButtonClass() {
      return [
        CSN_HORIZONTAL_GAME_LIST_BUTTON_RIGHT,
        this.showsRightButton
          ? EMPTY_STRING
          : CSN_HORIZONTAL_GAME_LIST_BUTTON_HIDDEN,
      ]
    },
    isEmptyList() {
      return isEmpty(this.list)
    },
  },
  watch: {
    itemsPerScreen() {
      if (!this.hasVericalUpperRow) {
        return
      }

      this.reset()
    },
    slug: {
      deep: true,
      handler() {
        this.reset()
      },
    },
  },
  methods: {
    handleLeftButtonClick() {
      this.$refs[this.ref].$emit(SLIDE_PRE)
    },
    handleRightButtonClick() {
      this.$refs[this.ref].$emit(SLIDE_NEXT)
    },
    slide({ currentPage: firstVisibleItem }) {
      const showsLeftButton = firstVisibleItem >= this.itemsPerScreen
      const showsRightButton =
        firstVisibleItem + this.itemsPerScreen <
        this.derivedHorizontalList.length

      this.showsLeftButton !== showsLeftButton &&
        (this.showsLeftButton = showsLeftButton)

      this.showsRightButton !== showsRightButton &&
        (this.showsRightButton = showsRightButton)
    },
    async loadFirstListFragment() {
      await this.updateList()

      const showsRightButton = Digit.SEVEN > this.itemsPerScreen
      this.showsRightButton !== showsRightButton &&
        (this.showsRightButton = showsRightButton)
    },
    async updateList() {
      this.isLoading = true

      const nextPage = this.page + Digit.ONE
      const { data, state } = await callFn(this.getList, { page: nextPage })

      if (state === ResponseState.OK) {
        const listDictionaryById = pipe(
          indexBy(ID),
          remove([undefined]),
        )(this.list || [])
        const newFragment = data?.items.filter(
          (el) => !listDictionaryById[el.id],
        )

        this.list = [...(this.list || []), ...newFragment]
        this.page = nextPage
        this.total = data.total
      }

      this.isLoading = false
    },
    reset() {
      this.resetRequest = requestTimeout(() => {
        this.list = null
        this.page = Digit.NOUGHT
        this.isLoading = false
        this.showsLeftButton = false
        this.showsRightButton = false

        this.loadFirstListFragment()
        cancelTimeout(this.resetRequest)
      }, Digit.FIVE_HUNDRED)
    },
  },
  mounted() {
    this.updateList()
  },
}
</script>
